<template>
  <div :class="['action-item', {'active': info.checked && info.selected}]">
    <div class="action-item__select" @click="$emit('select')" v-if="!plain">
      <i class="icon fa fa-check-circle selected" aria-hidden="true" v-if="info.checked && info.selected"></i>
      <i class="icon fa fa-circle-thin" aria-hidden="true" v-else></i>
      <div class="action-item__info">
        <h4>{{itemName}}</h4>
        <p v-html="$t('selection.tipCount', {count: info.total})"></p>
      </div>
    </div>
    <router-link :to="contentLink" class="action-item__entry" :class="{'empty': !info.photos.length}">
      <template v-if="info.photos.length">
        <div class="image" v-for="(image, index) in info.photos" :key="index"
             v-lazy:background-image="getImageUrl(image, 320)"></div>
      </template>
      <template v-else>
        <div class="text-ellipsis">{{info.content}}</div>
      </template>
      <div class="pages-name" v-if="plain">{{itemName}}</div>
      <i class="icon fa fa-angle-right" aria-hidden="true"></i>
    </router-link>
  </div>
</template>

<script>
import { getImageUrl } from 'src/_utils/'
import { mapGetters } from 'vuex'
import config from '../../app.config'
export default {
  name: '',
  props: {
    itemType: {
      type: String,
      default: 'facebook'
    },
    plain: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: function () {
        return {
          // month: 02,
          // time: 201202,
          content: '图片+视频+文字',
          checked: false,
          scid: '',
          selected: 123,
          total: 2,
          photos: []
        }
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    itemName () {
      let month
      let monthStr
      switch (this.itemType) {
        case 'facebook':
        case 'patch':
        case 'instagram':
          month = Number(this.info.month)
          if (!isNaN(month)) {
            config.months.forEach((item, index) => {
              if (month === index + 1) {
                monthStr = item
              }
            })
          }
          return this.language === 'en' ? monthStr : `${isNaN(Number(this.info.month)) ? this.info.month : Number(this.info.month)}月`
        default:
          return this.info.name
      }
    },
    contentLink () {
      if (this.plain) {
        return { name: 'pages', query: Object.assign({}, this.$route.query, { scid: this.info.scid, pages: this.info.pageId }) }
      }
      return { name: 'content', query: Object.assign({}, this.$route.query, { scid: this.info.scid }) }
    }
  },
  data () {
    return {}
  },
  created () {},
  methods: {
    getImageUrl
  }
}
</script>

<style type="text/scss" lang="scss">
@import '../../styles/variables';
.action-item + .action-item {
  margin-top: 1rem;
}
.action-item {
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #DFDFDF;
  width: 100%;
  cursor: pointer;
  &.active {
    background-color: #fff;
    .action-item__select i{
      color: $el-color-primary;
    }
  }
  i.icon {
    font-size: 2rem;
    color: #d9d9d9;
  }
  .action-item__select {
    position:relative;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0  10px 16px;
  }
  .action-item__info {
    margin-left: 1rem;
    h4 {
     font-size: 1rem;
      font-weight: 500;
    }
    p{
      font-size: 15px;
      color: #999;
      margin: 0;
    }
  }
  .pages-name {
    padding-left: 20px;
    flex: 1;
  }
  .action-item__entry {
    padding: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    .image + .image {
      margin-left: 1rem;
    }
    &.empty {
      overflow: hidden;
    }
    .image {
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 4px;
      background-color: #eee;
      background: {
        size: cover;
        repeat: no-repeat;
        position: 50%;
      }
      &[lazy=loading] {
        background-size: 48px;
      }
    }
    i.icon {
      margin-left: 1.15rem;
    }
  }
}
</style>
