<template>
  <div class="selection">
    <div class="row">
      <div class="col-xs-7 col-xs-offset-2">
        <div class="fresher" v-if="!isInitialized" @click="freshBookInfo" role="button">
          {{$t('selection.loadingContentFetching')}} <i class="fa fa-hand-pointer-o icon"></i> {{$t('selection.loadingButtonFresh')}}
        </div>
        <div class="importer" role="button" v-if="showImportAction" @click="showImportModal = true">
          <i class="fa fa-facebook-official"></i>一鍵導入臉書內容
        </div>
        <div class="sub-nav">
          <div :class="['sub-nav__item', {'active': tab.name === tabName}]" v-for="(tab, index) in tabs" :key="index"
               @click="setTabItem(tab.name)">
            <i :class="['fa', `fa-${tab.icon}`]" aria-hidden="true"></i>
            {{tab.label}}
          </div>
        </div>
      </div>
    </div>
    <div class="text-center loading" v-if="!bookLoaded">
      <p>
        <i class="fa fa-spinner fa-spin"></i>
      </p>
      <p>{{$t('selection.tipLoading')}}</p>
    </div>
    <div class="row selection__content" v-else>
      <div class="col-xs-2">
        <div class="side-timeline" v-show="tabName === 'facebook' || tabName === 'patch' || tabName === 'instagram'">
          <div :class="['time_year', {'active': currentYear === year}]" @click="setActiveYear(year)"
               v-for="(year, index) in currentYears" :key="index">{{year}}</div>
        </div>
      </div>
      <div class="col-xs-7">
        <template v-if="currentYearItems && currentYearItems.length">
          <action-item v-for="(item, index) in currentYearItems" :itemType="tabName"
                       @select="toggleSelect(item, index)" :plain="item.plain"
                       :info="item" :key="index"></action-item>
        </template>
        <template v-else>
          <div v-if="isAuth" class="text-center">
            {{$t('selection.tipEmpty')}}
            <p v-if="tabName !== 'patch'">
              授權導入更多數據
              <a class="text-primary" :href="authLink">點擊授權</a>
            </p>
          </div>
          <div class="text-center" v-else>
            <p>未授權當前信息</p>
            <a class="text-primary" :href="authLink">
              點擊授權
            </a>
          </div>
        </template>
      </div>
      <div class="col-xs-2 selection__actions">
        <button class="btn primary" @click="saveContent(false)">
          <i class="fa fa-eye" aria-hidden="true"></i>
          {{$t('selection.buttonSave')}}
        </button>
        <button class="btn plant" @click="showModal = true">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
          {{$t('selection.buttonPatch')}}
        </button>
      </div>
      <modal :show="showModal" class="patch-modal">
        <patch-item ref="patch" slot="body"></patch-item>
        <div class="clearfix" slot="footer">
          <button class="btn plant pull-left" @click="showModal = false">{{$t('button.cancel')}}</button>
          <button class="btn primary pull-right" :disabled="patchSaving" @click="savePatchItem">
            <i class="fa fa-spinner fa-spin" v-show="patchSaving" />
            {{$t('button.ok')}}
          </button>
        </div>
      </modal>

      <modal :show="showImportModal" @close="showImportModal = false">
        <content-import is-step="step"
                        @close="showImportModal = false"
                        @select="selectSource"
                        slot="body"/>
      </modal>
    </div>
  </div>
</template>

<script>
import api from 'src/api'
import { findIndex, orderBy, isEmpty } from 'lodash'
import ActionItem from 'src/components/layout/ActionItem.vue'
import PatchItem from './PatchItem.vue'
import ContentImport from '../../Shelf/ContentImport'
import { mapGetters, mapActions } from 'vuex'
import '../../../styles/layout/Selection.scss'
export default {
  name: '',
  components: { ActionItem, PatchItem, ContentImport },
  data: () => ({
    tabName: 'facebook',
    years: [],
    insYears: [],
    patchYears: [],
    bookSource: {}, // 来源数据
    activeYear: '',
    activeInsYear: '',
    activePatchYear: '',
    bookInfo: {},
    showModal: false,
    workBox: { facebook: [], patch: [], albums: [], instagram: [] },
    dataLoaded: { facebook: false, patch: false, pages: false, albums: false, instagram: false },
    contentItems: [],
    saving: false,
    isAuthed: false,
    showImportModal: false,
    showImportAction: false,
    patchSaving: false
  }),
  computed: {
    ...mapGetters(['user']),
    tabs () {
      return [
        {
          icon: 'facebook-official',
          name: 'facebook',
          label: this.$t('selection.tabFacebook')
        },
        {
          icon: 'facebook-official',
          name: 'albums',
          label: this.$t('selection.tabAlbums')
        },
        {
          icon: 'flag',
          name: 'pages',
          label: this.$t('selection.tabPages')
        },
        {
          icon: 'instagram',
          name: 'instagram',
          label: 'Instagram'
        },
        {
          icon: 'pencil-square',
          name: 'patch',
          label: this.$t('selection.tabPatch')
        }
      ]
    },
    authLink () {
      const bid = this.$route.query.bid
      const { uid } = this.user
      if (this.tabName === 'instagram') {
        return `/social/instagram_data?bid=${bid}&uid=${uid}`
      }
      let source
      switch (this.tabName) {
        case 'facebook':
        case 'albums':
          source = 'posts'
          break
        case 'pages':
          source = 'pages'
          break
      }
      return `/social/facebook_data?bid=${bid}&uid=${uid}&source=${source}`
    },
    currentYearItems () {
      let year
      switch (this.tabName) {
        case 'facebook':
        case 'instagram':
          year = this.tabName === 'facebook' ? this.activeYear : this.activeInsYear
          return this.bookLoaded
            ? this.bookInfo[this.tabName][year]
            : []
        case 'patch':
          return this.bookLoaded
            ? (isEmpty(this.bookInfo.patch) ? [] : this.bookInfo.patch[this.activePatchYear])
            : []
        default:
          return this.bookLoaded ? this.bookInfo[this.tabName] : []
      }
    },
    currentYears () {
      if (!this.bookLoaded) return []
      if (this.tabName === 'facebook') {
        return this.years
      }
      if (this.tabName === 'instagram') {
        return this.insYears
      }
      return this.patchYears
    },
    currentYear () {
      if (!this.bookLoaded) return ''
      if (this.tabName === 'patch') {
        return this.activePatchYear
      }
      if (this.tabName === 'instagram') {
        return this.activeInsYear
      }
      return this.activeYear
    },
    bookLoaded () {
      return this.dataLoaded[this.tabName]
    },
    isAuth () {
      if (!this.dataLoaded[this.tabName]) return false
      const source = this.bookSource[this.tabName]
      if (!source) {
        return false
      } else {
        const { isGettingPosts, isGettingPages, postsInitialized, pagesInitialized, insInitialized, isGettingIns } = source
        switch (this.tabName) {
          case 'facebook':
          case 'albums':
            return isGettingPosts || postsInitialized
          case 'instagram':
            return isGettingIns || insInitialized
          case 'patch':
            return true
          case 'pages':
            return isGettingPages || pagesInitialized
          default:
            return true
        }
      }
    },
    isInitialized () {
      if (!this.bookLoaded) return true
      const source = this.bookSource[this.tabName]
      if (!source) {
        return true
      } else {
        const { isGettingPosts, isGettingPages, isGettingIns } = source
        switch (this.tabName) {
          case 'facebook':
          case 'albums':
            return !isGettingPosts
          case 'instagram':
            return !isGettingIns
          case 'patch':
            return true
          case 'pages':
            return !isGettingPages
          default:
            return false
        }
      }
    }
  },
  created () {
    const { sid } = this.$route.query
    this.tabName = this.$route.query.tab || 'facebook'
    this.fetchActiveInfo(sid, this.tabName)
  },
  beforeRouteLeave (to, from, next) {
    if (!this.saving) this.saveContent(true)
    next()
  },
  methods: {
    ...mapActions(['setLoading']),
    // 请求素材库内容
    fetchActiveInfo (sourceId, tabName, force = false) {
      if (this.dataLoaded[tabName] && !force) {
        return
      }
      this.dataLoaded[tabName] = false
      api.fetchSocialInfo(sourceId, tabName).then(data => {
        if (!data || data.errors) {
          const msg = data.errors ? `<br>錯誤碼 - ${data.errors.code}` : ''
          this.$toast({
            message: this.$t('selection.tipContentFetching', { msg }),
            position: 'bottom'
          })
          return
        }
        const social = this.resolveActiveItems(data, tabName)
        const { year, tab } = this.$route.query
        const { isGettingPosts, isGettingPages, postsInitialized, pagesInitialized, insInitialized, isGettingIns } = data
        this.bookSource[tabName] = data
        // 是否显示导入操作按钮
        this.showImportAction = !isGettingPages && !isGettingPosts && !postsInitialized && !pagesInitialized && !insInitialized && !isGettingIns
        switch (tabName) {
          case 'facebook':
          case 'albums':
            this.isAuthed = isGettingPosts || postsInitialized
            break
          case 'patch':
            this.isAuthed = true
            break
          case 'pages':
            this.isAuthed = isGettingPages || pagesInitialized
            break
          case 'instagram':
            this.isAuthed = isGettingIns || insInitialized
            break
        }

        if (tabName === 'facebook') {
          this.years = social.years
          this.activeYear = (!tab || tab === 'facebook') && year ? year : social.activeYear
          this.bookInfo[tabName] = social.timeLine
          this.contentItems = this.bookInfo[tabName][this.activeYear] || []
        } else if (tabName === 'instagram') {
          this.insYears = social.years
          this.activeInsYear = (!tab || tab === 'instagram') && year ? year : social.activeYear
          this.bookInfo[tabName] = social.timeLine
          this.contentItems = this.bookInfo[tabName][this.activeInsYear] || []
        } else if (tabName === 'patch') {
          this.patchYears = social.years
          this.activePatchYear = tab === 'patch' && year ? year : social.activeYear
          this.bookInfo[tabName] = social.timeLine
          this.contentItems = isEmpty(this.bookInfo.patch) ? [] : this.bookInfo.patch[this.activePatchYear]
        } else {
          this.bookInfo[tabName] = social.data
          this.contentItems = this.bookInfo[tabName] || []
        }
        // 状态加载放置最后
        this.dataLoaded[tabName] = true
      })
    },
    // 刷新书籍社交内容
    freshBookInfo () {
      // 设置所有 dataLoaded 状态为 false
      Object.keys(this.dataLoaded).forEach(item => {
        this.dataLoaded[item] = false
        this.bookInfo[item] = []
      })
      // 更新当前 tab 栏内容
      this.setTabItem(this.tabName)
    },
    // 切换社交内容
    setTabItem (name) {
      this.tabName = name
      const query = Object.assign({}, this.$route.query, { tab: name })
      delete query.year
      if (isEmpty(this.bookInfo[name]) && !this.dataLoaded[name]) {
        this.fetchActiveInfo(query.sid, name)
      } else {
        if (/facebook|instagram/g.test(name)) {
          const year = name === 'facebook' ? this.activeYear : this.activeInsYear
          this.contentItems = this.bookInfo[name][year] || []
        } else if (name === 'patch') {
          this.contentItems = isEmpty(this.bookInfo.patch) ? [] : this.bookInfo.patch[this.activePatchYear]
        } else {
          this.contentItems = this.bookInfo[name]
        }
      }
      this.$router.push({ path: this.$route.path, query })
    },
    setActiveYear (year) {
      if (/facebook|instagram/g.test(this.tabName)) {
        if (this.tabName === 'facebook') {
          this.activeYear = year
        } else {
          this.activeInsYear = year
        }
        this.contentItems = this.bookInfo[this.tabName][year]
      } else {
        this.activePatchYear = year
        this.contentItems = this.bookInfo.patch[year]
      }
      const query = Object.assign({}, this.$route.query, { year })
      this.$router.push({ path: this.$route.path, query })
    },
    toggleSelect (item, index) {
      if (item.checked && !item.selected) {
        this.$toast({
          message: this.$t('selection.tipRecover')
        })
        return
      }
      this.$set(this.currentYearItems[index], 'checked', !item.checked)
      let itemId
      switch (this.tabName) {
        case 'facebook': // 个人页
        case 'patch': // 补写内容
        case 'instagram':
          itemId = item.time
          break
        case 'albums':
          itemId = item.albumId
          break
        case 'pages':
          itemId = item.pageId
          break
      }
      const activeIndex = findIndex(this.workBox[this.tabName], { itemId })
      if (activeIndex > -1) {
        this.workBox[this.tabName].splice(activeIndex, 1)
      } else {
        this.workBox[this.tabName].push({ itemId, selected: item.checked })
      }
    },
    // 保存补写内容
    savePatchItem () {
      if (this.patchSaving) return
      const { content, title, date, time, images } = this.$refs.patch
      let picList = images.slice()
      picList = picList.map(item => {
        return item[0]
      })
      if (!content.trim() && !picList.length) {
        this.$toast({
          message: this.$t('selection.tipCompletedPatch')
        })
        return
      }
      const dateArr = date.split('-')
      if (dateArr[0].length !== 4) {
        this.$toast({
          message: this.$t('selection.tipTimeError')
        })
        return
      }
      const item = {
        title,
        content,
        postDate: `${date}T${time}`,
        picList
      }
      this.$toast({
        message: this.$t('selection.tipSaving'),
        duration: 1000,
        position: 'bottom'
      })
      const { sid } = this.$route.query
      this.patchSaving = true
      api.createItemContent(sid, item).then(data => {
        this.$toast({
          message: this.$t('selection.tipSaved'),
          duration: 1500,
          icon: 'fa-check-circle'
        })
        this.$refs.patch.content = ''
        this.$refs.patch.images = []
        this.fetchActiveInfo(sid, 'patch', true)
      }).catch(e => {
        console.error(e)
      }).finally(_ => {
        this.showModal = false
        this.patchSaving = false
      })
    },
    resolveChapterItem (socialData) {
      const timeLine = {}
      let years = []
      const patchTime = {}
      let patchYears = []
      const bookInfo = { facebook: [], albums: [], pages: [] }
      Object.keys(socialData).forEach(key => {
        const checked = socialData[key].checked.map(item => {
          item.checked = true
          return item
        })
        const unchecked = socialData[key].unchecked.map(item => {
          item.checked = false
          return item
        })
        bookInfo[key] = checked.concat(unchecked)
        if (key === 'facebook' || key === 'patch') {
          bookInfo[key] = orderBy(bookInfo[key], ['month'], ['asc']) // 按照章节月份正序
          if (key === 'facebook') {
            bookInfo[key].forEach(item => {
              const year = item.month.substr(0, 4)
              const month = item.month.substr(4, 2)
              timeLine[year] = timeLine[year] || []
              timeLine[year].push(Object.assign(item, { month, time: item.month }))
            })
          } else {
            bookInfo[key].forEach(item => {
              const year = item.month.substr(0, 4)
              const month = item.month.substr(4, 2)
              patchTime[year] = patchTime[year] || []
              patchTime[year].push(Object.assign(item, { month, time: item.month }))
            })
          }
        } else {}
      })
      years = Object.keys(timeLine).reverse()
      patchYears = Object.keys(patchTime).reverse()
      const activeYear = years[0]
      const activePatchYear = patchYears[0]
      return { timeLine, years, patchYears, activeYear, activePatchYear, social: { ...bookInfo, facebook: timeLine, patch: patchTime } }
    },
    resolveActiveItems (data, type) {
      if (type === 'pages') {
        data.pages.forEach(item => {
          item.photos = [item.picture]
          item.checked = true
          item.selected = true
          item.plain = true
        })
        return { data: data.pages }
      }
      const timeLine = {}
      let years = []
      let activeYear = ''
      const checked = data[type].checked.map(item => {
        item.checked = true
        if (!item.selected) {
          let itemId
          switch (type) {
            case 'facebook': // 个人页资料
            case 'patch': // 个人页资料
            case 'instagram': // 个人页资料
              itemId = item.month
              break
            case 'albums':
              itemId = item.albumId
              break
            case 'pages':
              itemId = item.pageId
              break
          }
          this.workBox[type].push({ itemId, selected: false }) // 进入删除列表
        }

        return item
      })
      const unchecked = data[type].unchecked.map(item => {
        item.checked = false
        return item
      })
      data[type] = checked.concat(unchecked)
      switch (type) {
        case 'facebook':
        case 'patch':
        case 'instagram':
          data[type] = orderBy(checked.concat(unchecked), ['month'], ['asc'])
          data[type].forEach(item => {
            const year = item.month.substr(0, 4)
            const month = item.month.substr(4, 2)
            timeLine[year] = timeLine[year] || []
            timeLine[year].push(Object.assign(item, { month, time: item.month }))
          })
          years = Object.keys(timeLine).reverse()
          activeYear = years[0]
          return { timeLine, activeYear, years, data: data[type] }
        default:
          return { timeLine, activeYear, years, data: data[type] }
      }
    },
    saveContent (force = false) {
      const updateContent = { facebook: {}, albums: {}, patch: {}, instagram: {} }
      Object.keys(this.workBox).forEach(key => {
        updateContent[key].add = []
        updateContent[key].remove = []
        this.workBox[key].forEach(item => {
          if (item.selected) {
            updateContent[key].add.push(item.itemId)
          } else {
            updateContent[key].remove.push(item.itemId)
          }
        })
      })
      if (!force) {
        this.$toast({
          message: this.$t('selection.tipUpdating'),
          position: 'bottom',
          duration: 1000
        })
      }
      api.updateSocialBook(this.$route.query.sid, updateContent).then(data => {
        if (!force) {
          const query = Object.assign(this.$route.query)
          delete query.tab
          this.$toast({
            message: this.$t('selection.tipUpdated'),
            icon: 'fa'
          })
          this.saving = true
          this.$router.push({ name: 'preview', query })
        }
      })
    },
    // 选择社交内容来源
    selectSource (sources) {
      this.showImportModal = false
      this.setLoading(true)
      const { bid } = this.$route.query
      const { uid } = this.user
      let facebookImportLink = `/social/facebook_data?uid=${uid}&bid=${bid}`
      facebookImportLink = sources.reduce((total, item) => {
        total = `${total}&source=${item}`
        return total
      }, facebookImportLink)
      window.location.href = facebookImportLink
    }
  }
}
</script>
